* {
  margin: 0;
  padding: 0;
  
}


#logo-header {
  z-index: 999999;
}

a {
  text-decoration: none;
}
.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  opacity: 1;
  /* background-image: url(../static/sky.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

}

p {
  font-size: 20px;
}

h3 {
  font-size: 60px;
}

#builtauxo2 {
  display: none;
}


head {
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

}

html,
body {
  overflow: hidden;
  height: 100vh;
  display: flex;
  font-family: -apple-system, Roboto, sans-serif;
  background-color: #001722;
  width: 100%;

  /* justify-content: center; */
  /* align-items: center; */
}

.loading-bar {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  padding: 20px;
  top: 50%;
}

.spin {
  background-color: #005b8520;
  height: 18vw;
  width: 18vw;
  border-radius: 250px;
  border: 7px solid #005b8500;
  border-top: 7px solid #6a925d;
  animation: loading 2s linear infinite;
  will-change: transform;
  /* display: flex; */
  /* transition: display 3s ease; */
}

.spin.ended {
  /* transform: scale(1.25); */
  transition: transform 0.5s;
  animation: none;
  /* opacity: 0; */
  border-top: 7px solid #005b8500;
}

.loading {
  position: absolute;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

.metsi-logo {
  width: 12vw;
  height: 12vw;
  background-image: url("../static/images/metsi-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  /* display: flex;
  transition: display 3s; */
}

.metsi-logo.ended {
  transform: scale(0);
  transition: transform 0.25s;
  will-change: transform;
}

.wrapper1 {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-color: #001722;
  color: #ffffff;
  /* padding-top: 150px; */
  z-index: 99999;
  justify-content: center;
  align-items: center;
  
}

.wrapper1.ended {
  opacity: 0;
  transition: 0.5s;
  z-index: 0;
}

.wrapper2 {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  color: #ffffff;
  padding-top: 0px;
  margin-top: -80vh;
}

.wrapper3 {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  color: #ffffff;
  padding-top: 0px;
  /* margin-top: -80vh; */
  /* z-index: 100; */
}

.text {
  /* padding-top: 50px; */
  position: relative;
  /* display: flex; */
  width: 100%;
  height: 100%;
  color: #ffffff;
  /* background-color: #ffffff; */
}

.text h3 {
  font-size: 26px;
}

.text p {
  font-size: 20px;
}

.text.ended {
  transform: translateY(-200px) scale(0);
  transition: transform 0.25s;
  will-change: transform;
}

.nav {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav li {
  float: left;
  display: block;
  color: white;
  text-align: center;
  /* padding: 16px; */
  text-decoration: none;
}
.metsi-logo2 {
  /* width: ; */
  height: 100%;
  /* padding: 5px; */
  background-image: url(../static/images/metsi-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position-x: center; */
  background-position-y: center;
}

.logo-wrap {
  width: 5vw;
  height: 5vw;
  padding: 20px;
  border-radius: 50%;
  background-color: #005b85;
  /* background-position-x: center; */
  background-position-y: center;
}

.header {
  display: flex;
  background-color: transparent;
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
  margin: 0;
  height: 6vw;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  z-index: 9999;
}

button {
  font-size: 14px;
  /* border-radius: 10px; */
  padding: 8px 12px 8px 12px;
  background-color: #005b85;
  color: #fff;
  border: none;
  /* transition: ease 1.2s; */
  font-weight: 200;
  /* text-transform: uppercase; */
}

button2 {
  /* margin-top: 60px; */
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 12px 8px 12px;
  background-color: #005b85;
  color: #fff;
  border: solid 2px #fff;
  transition: ease 1.2s;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  /* position: absolute; */
}

button2:hover {
  cursor: pointer;
  border-color: #005b85;
  color: #005b85;
  background-color: #fff;
  transition: ease 1.2s;
}

button4 {
  /* margin-top: 60px; */
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 12px 8px 12px;
  background-color: #6d9461;
  color: #fff;
  border: solid 2px #fff;
  transition: ease 1.2s;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  /* position: absolute; */
}

button4:hover {
  cursor: pointer;
  border-color: #6d9461;
  color: #6d9461;
  background-color: #fff;
  transition: ease 1.2s;
}

h1 {
  font-size: 60px;
  color: #005b85;
  padding: 0px 0 20px 0;
  font-family: "Archivo Black", sans-serif;
  font-weight: 800;
}

h2 {
  font-family: -apple-system, Roboto, sans-serif;
  color: rgb(70, 70, 70);
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 300;
}

.menu {
  position: relative;
  display: flex;
  width: 100%;
  background-color: transparent;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding-right: 360px;
}

.menu a {
  float: left;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: right;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;

  font: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: transparent;
  width: 100%;
  /* left: 0.5vw; */
  /* padding-top: 5px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: transparent;
  width: 100%;
  left: 0;
  /* padding-top: 5px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Create three equal columns that floats next to each other */
.column1 {
  /* float:left; */
  width: 10%;
  /* padding: 10px; */
  background-color: #005b85be;
  height: 350px;
}

.col-resp1 {
  /* float:left; */
  /* width: 100%; */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60vw;
}

h3 {
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  max-width: 50%;
}

/* Style links inside the columns */
.column a {
  float: none;
  color: white;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.column1 a {
  float: none;
  color: white;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a background color on hover */
.column a:hover,
.column1 a:hover {
  background-color: #005b85;
}

/* Clear floats after the columns */

.row {
  display: flex;
  flex-direction: row;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Dropdown Button */
.dropbtn2 {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container needed to position the dropdown content */
.dropdown2 {
  position: relative;
  display: inline-block;
  /* margin-top:24px; */
  /* margin-right: 50px; */
  color: #fff;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: rgba(16, 91, 133, 0.6);
  min-width: 100px;
  justify-content: left;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content2 a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content2 :hover {
  background-color: #005b85;
}

.mm1 {
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
}

/* Show the dropdown menu on hover */
.dropdown2:hover .dropdown-content2 {
  display: flex;
  flex-direction: column;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown2:hover .dropbtn2 {
  background-color: #3e8e41;
}

.container {
  transform: scale(0.5);
  opacity: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 1;
  /* height: 90vh; */
  /* padding: 0 100px 0 100px; */
  width: 100%;
  will-change: transform;
  padding: 0;
}

.container.load {
  transform: scale(1);
  opacity: 1;
  transition: 1s;
  height: 95vh;
}

.container.load.click {
  transform: scale(0.50);
  opacity: 0;
  transition: 0.5s;
}

.inner-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 40px;
  /* padding-top: 80px; */
  /* padding-bottom: 80px; */
  max-width: 80vw;
  /* max-height: 40vh; */
  justify-content: center;
  align-items: center;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sign {
  position: relative;
  z-index: 10;
  width: 200px;
  height: 200px;
  background-image: url("../static/icons/0.png");
  background-size: contain;
}

.number1 {
  display: block;
}

.number1.click {
  display: none;
}

.number2 {
  display: none;
}

.number2.click {
  display: block;
}

.number3 {
  display: none;
}

.number3.click {
  display: block;
}

.number3a {
  display: none;
}

.number3a.click {
  display: block;
}

.number4 {
  display: none;
}

.number4.click {
  display: block;
}

p {
  color: black;
}


p :hover {
  transform: rotate(
0deg);
}


/* 7 paths to summit of application modernisation */
.column-path1-2 :hover,
.column-path2-2 :hover,
.column-path3-2 :hover,
.column-path4-2 :hover,
.column-path5-2 :hover,
.column-path6-2 :hover,
.column-path7-2 :hover{
  cursor: pointer;
}

/* 7 paths to summit of application modernisation */
.column-path1 :hover,
.column-path2 :hover,
.column-path3 :hover,
.column-path4 :hover,
.column-path5 :hover,
.column-path6 :hover,
.column-path7 :hover{
  cursor: pointer;
  /* transform: rotate(270deg);
  transform: scale(1.05); */
  /* color: #cccccc; */
  transition: 0.5s ease;
}





/* Rehost */
.column-path1 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;
  border: 4px solid#005b85;
  background-color: #005b85;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path1-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}

.column-path1.click,
.column-path2.click,
.column-path3.click,
.column-path4.click,
.column-path5.click,
.column-path6.click,
.column-path7.click {
  border: 4px solid #005b85;
  background-color: #005b85c0;
  transform: scale(1.1);
  transition: ease;
}

.column-path1-2.click {
  display: flex;
}



/* Replatform */
.column-path2 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#1ab4e9;
  background-color: #1ab4e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path2-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path2-2.click {
  display: flex;
}


/* Repurchase */
.column-path3 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#335b30;
  background-color: #335b30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path3-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path3-2.click {
  display: flex;
}


/* Refactor */
.column-path4 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#6a925d;
  background-color: #6a925d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path4-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path4-2.click {
  display: flex;
}


/* Encapsulate */
.column-path5 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#335b30;
  background-color: #335b30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path5-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path5-2.click {
  display: flex;
}


/* Retain */
.column-path6 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#1ab4e9;
  background-color: #1ab4e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path6-2 {
  width: 100%;
  min-height: 20vh;
  padding: 20px;
  
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path6-2.click {
  display: flex;
}


/* Retire */
.column-path7 {
  width: 6vw;
  height: 6vw;
  border-radius: 50% 50% 50% 0%;;
  border: 4px solid#005b85;
  background-color: #005b85;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-path7-2 {
  width: 100%;
  min-height: 20vh;
  background-color: #005b85;
  display: none;
  justify-content: center;
  /* align-items: center; */
}



.column-path7-2.click {
  display: flex;
}

.spaces {
  width: 10px;
  display: block;
}

.column-path8-2 {
  width: 100%;
  min-height: 20vh;
  background-color: #005b85;
  /* display: none; */
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */

}



.column-path1-2 p,
.column-path2-2 p,
.column-path3-2 p,
.column-path4-2 p,
.column-path5-2 p,
.column-path6-2 p,
.column-path7-2 p,
.column-path1 p,
.column-path2 p,
.column-path3 p,
.column-path4 p,
.column-path5 p,
.column-path6 p,
.column-path7 p {
  color: white;
  font-weight: 500;
  /* font-size: 10px; */
  /* transform: rotate(270deg); */
  /* text-align: left; */
}

.column-path1-2.click p,
.column-path2-2.click p,
.column-path3-2.click p,
.column-path4-2.click p,
.column-path5-2.click p,
.column-path6-2.click p,
.column-path7-2.click p {
  color: white;
  font-weight: 500;
  /* font-size: 14px; */
  transform: rotate(0deg);
  transition: transform none;
  padding: 0;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* text-align: left; */
}

.column-path1-2.click,
.column-path2-2.click,
.column-path3-2.click,
.column-path4-2.click,
.column-path5-2.click,
.column-path6-2.click,
.column-path7-2.click {
  color: white;
  font-weight: 500;
  /* font-size: 10px; */
  transform: rotate(0deg);
  transition: transform none;
  padding: 0;

  /* text-align: left; */
}

.column-path8-2.click{
  display: none;
}

.column-path8-2 p {
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}



.paths-diagram {
  width: 100%;
  min-height: 150px;
  background-image: url(../static/images/7-paths.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  padding-bottom: 150px;
}

.summit {
  width: 100%;
  min-height: 100px;
  background-image: url(../static/icons/8.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  /* padding-bottom: 150px; */
}

.footer {
    padding: 0px 0 20px 0;
    background: transparent;
    color: #f1f1f1;
    position: fixed;
    left: 0;
    bottom: 0;  
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 20px; */
}

.step1, .step2, .step3 {
    width: 40px;
    height: 40px;
    background-color: #005b85;
    border-radius: 50% 0 50% 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
}

.step1b, .step2b, .step3b {
  width: 40px;
  height: 40px;
  background-color: #1ab4e9;
  border-radius: 50% 0 50% 50%;
  border: 3px solid #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  display: none;
}


.step1.click, .step2.click, .step3.click {
  display: none;
}

.step1b.click, .step2b.click, .step3b.click {
  display: block;
}

.camp1 {
  width: 15vw;
  height: 15vw;
  background-image: url("../static/icons/3-2.png"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
  /* border: 2px solid white; */
  border-radius: 50%;


}

.icon-border {
  /* width: 15.5vw; */
  height: 100%;
  background-image: url("../static/icons/icon-bkg.png"); 
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  animation: spin 10s infinite;
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg);
  }
}

.camp2 {
  width: 20vh;
  height: 20vh;
  background-image: url("../static/icons/am-diag-thumb.jpg"); 
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  animation: pulse 2s infinite;
  transition: 700ms;
}

.camp2:hover{
  cursor: pointer;
filter: brightness(60%);
transition: filter 1s;
}


.camp3 {
  width: 15vw;
  height: 15vw;
  background-image: url("../static/icons/2-2.png"); 
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
border-radius: 50%;
margin-right: 15px;

}


.camp-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.camp-column h1 {
  font-size: 54px;
}

.camp-column h2 {
  font-size: 32px;
}

a :hover {
  cursor: pointer;
}

.paths-modal {
  padding: 20px 0 20px 0;
  background: rgba(0, 0, 0, 0.6);
  color: #f1f1f1;
  position: fixed;
  left: 0;
  bottom: 0;  
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 20px; */
}

.paths-modal.click {
display: flex;
}

.paths-image {
  width: 90vw;
  height: 80vh;
  background-image: url(../static/icons/am-diag.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.begin {
  display: none;
  /* opacity: 0; */
  /* transform: scale(0.5); */
}

.begin.loaded {
  display: block;
}

button3 {
  /* margin-top: 60px; */
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 12px 8px 12px;
  background-color: #001722;
  color: #6a925d;
  border: solid 2px #6a925d;
  transition: ease 1.2s;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  /* position: absolute; */
}

button3:hover {
  cursor: pointer;
  border-color: #001722;
  color: #001722;
  background-color: #fff;
  transition: ease 1.2s;
}

.resp-row1, .resp-row2, .resp-row3 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.enlarge {
  /* font-size: 14px;  */
  padding-top: 20px; 
  color: #555;
}

#resp-col1 {
  margin-top: 20px;
  margin-left: 20px;
}

#resp-col1-2 {
  margin-top: 20px;
}

.btn-resp2 {
  width: 100%;
  display: flex;
}

#loadscreen-text {
  max-width: 90vw;
  font-size: larger;
}


#landingtag {
  font-size: x-large;
}


.soundon {
  
  width: 3vw;
  height: 3vw;
  background-image: url("../static/icons/soundoff.png"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
  /* border: 2px solid white; */
  border-radius: 50%;
  cursor: pointer;

}

.soundon.click {
display: none;
}

.soundoff {

width: 3vw;
height: 3vw;
background-image: url("../static/icons/sound.png"); 
background-position: center;
background-repeat: no-repeat;
background-size: contain;
margin-right: 15px;
/* border: 2px solid white; */
border-radius: 50%;
display: none;
cursor: pointer;
}

.soundoff.click {
display: initial;
}

#footer-text {
  font-size: large;
}

@media screen and (max-width: 2080px) {
  p {
    font-size: 14px;
  }

  h3 {
    font-size: 48px;
  }

  h2 {
    font-size: 24px;
  }

  .camp-column h1 {
    font-size: 34px;
  }

  .camp-column h2 {
    font-size: 20px;
  }
  
}

@media screen and (max-width: 1340px) {
  
  .resp-row1 h2, .resp-row1 h1, .resp-row1 h3, .resp-row1 p {
    text-align: center;
  }
  .resp-row1{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .btn-resp2 {
    justify-content: center;
    align-content: center;
  }

  .column-path1,
  .column-path2,
  .column-path3,
  .column-path4,
  .column-path5,
  .column-path6,
  .column-path7{

    width: 10vw;
    height: 10vw;

  }

  .camp3 {
    width: 16vw;
    height: 16vw;
  }

  h3 {
    font-size: 48px;
  }

  p {
    font-size: 16px;
  }

}

#soundtoggle {
  top: 40px;
  right: 70px;
}

@media screen and (max-width: 1008px) {

  
#soundtoggle {
  top: 30px;
  right: 50px;
}

  #footer-text {
    font-size: medium;
  }
  
  .resp-row1 h2, .resp-row1 h1, .resp-row1 p {
    text-align: center;
    font-size: small;
  }

  #landingtag {
    font-size: large;
  }

  #loadscreen-text {
    font-size: medium;
  }

  .soundon {
    width: 6vw;
    height: 6vw;
  }

  .soundoff {
    width: 6vw;
    height: 6vw;
  }

  .camp-column h1{
    font-size: 32px;
  }

  .resp-row2{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .spin {
    width: 25vw;
    height: 25vw;
  }

  .column-path1,
  .column-path2,
  .column-path3,
  .column-path4,
  .column-path5,
  .column-path6,
  .column-path7{

    width: 8vw;
    height: 8vw;
    font-size: 12px;
  }

  .camp2 {
    width: 12vh;
    height: 12vh;
    margin: 0 !important;
  }


  .column-path1-2,
  .column-path2-2,
  .column-path3-2,
  .column-path4-2,
  .column-path5-2,
  .column-path6-2,
  .column-path7-2,
  .column-path8-2{

    min-height: 12vh;
    font-size: 12px;
}

.header {
  padding-left: 40px;
  padding-right: 0;
}

}

.enlarge2 {
  display: none;
  /* font-size: 14px;  */
  padding-top: 20px; 
  color: #444;
}

@media screen and (max-width: 760px) {

  .button_base {
    transform: scale(0.95);
  }

  #footer-text {
    font-size: small;
  }

  .metsi-logo2 {
    transform: scale(1.5);
  }

  .enlarge2 {
    display: initial;
  }

  .modal-popup {
    display: none;
  }

  .spin {
    width: 26vw;
    height: 26vw;
  }

  .metsi-logo {
    width: 16vw;
    height: 16vw;
  }

  .enlarge {
    padding-top: 5px;
    font-size: 10px;
  }
  
  .resp-row1 h2, .resp-row1 h1, .resp-row1 p {
    text-align: center;
  }

  #resp-col1, #resp-col1-2 {
    margin: 0;
  }

  .camp-column h1{
    font-size: 24px;
  }
  .camp-column h2{
    font-size: 16px;
  }
  .resp-row1{
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.resp-row3{
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-resp{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.camp3, .camp1 {
  display: none;
}

.header {
  padding-left: 20px;
  padding-right: 0;
}

.camp-column p {
  text-align: center;
}

.camp-column h2 {
  text-align: center;
}

.camp-column h1 {
  text-align: center;
}

.column-path1,
.column-path2,
.column-path3,
.column-path4,
.column-path5,
.column-path6,
.column-path7{

  width: 12vw;
  height: 12vw;

}

p {
  font-size: 12px;
}

h3 {
  font-size: 28px;
}


#builtauxo {
  font-size: 16px;
}

#builtauxo2 {
  font-size: 16px;
}

}

#paths-div {
  padding-left: 20px;
}

@media screen and (max-width: 600px) {

  .step1, .step1b, .step2, .step2b, .step3, .step3b {
    transform: scale(0.8);
  }

  .footer {
    padding: 0 0 10px 0;
  }

  #footer-text {
    display: none;
  }

    #loadscreen-text {
    font-size: medium;
  }

  #rehost,
#replatform,
#repurchase,
#refactor,
#encapsulate,
#retain,
#retire{
  transform: scale(0.9);
}

.spaces {
  display: none;
}

  .button_base {
    transform: scale(0.8);
  }

  #builtauxo {
    display: none;
  }

  #builtauxo2 {
    /* display: initial; */
    font-size: 12px;
    padding: 0;
  }

  #soundtext {
    display: none;
  }

  #landingtag {
    font-size: small;
}

  .soundon {
    width: 10vw;
    height: 10vw;
    margin-right: -20px;
  }

  .soundoff {
    width: 10vw;
    height: 10vw;
    margin-right: -20px;
  }

  .spin {
    width: 30vw;
    height: 30vw;
  }

  .metsi-logo {
    width: 18vw;
    height: 18vw;
  }

.column-path1,
.column-path2,
.column-path3,
.column-path4,
.column-path5,
.column-path6,
.column-path7{

  width: 18vw;
  height: 6vw;
  border-radius: 5px;
  padding: 5px;

}



#loadscreen-text {
  padding: 0;
  max-width: 100%;
}

#paths-div {
  padding: 0;
}

.camp2 {
  height: 8vh;
}

.resp-row1 h2, .resp-row1 h1, .resp-row1 p {
  text-align: center;

}

/* h3 {
  font-size: 20px;
} */

p {
  font-size: 14px;
}

.enlarge2 {
  padding-top: 5px;
}

#paths-image2 {
  margin: -10px;
}

h1{
  font-size: 24px;
}

h3{
  font-size: 28px;
}


.column-path1-2 p,
.column-path2-2 p,
.column-path3-2 p,
.column-path4-2 p,
.column-path5-2 p,
.column-path6-2 p,
.column-path7-2 p,
.column-path8-2 p{

  margin: 5px;
}

button2{
  font-size: 12px;
  padding: 4px 6px;
}

button4{
  font-size: 12px;
  padding: 4px 6px;
}

}

/* @media screen and (max-width: 400px) {

  .camp-column h1 {
    font-size: 20px;
  }

  .camp-column h2 {
    font-size: 14px;
  }
} */

.transparent-cover{
  /* background-color: red; */
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 100vh;
  z-index: 9;
  display: none;
}

.transparent-cover.click{
 display: initial;
}

.built-auxo{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  position: fixed;
  /* width: 10vw; */
  bottom: 0;
  left: 6vw;
  height: 10vh;
  z-index: 9;
  /* display: none; */
}



/* ### ### ### Button 08 */


.button_base {
  margin: 0;
  margin-top: 12pt;
  margin-right: 12pt;
  border: 0;
  font-size: 18px;
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  /* margin-top: -25px; */
  /* margin-left: -100px; */
  width: 150px;
  height: 40px;
  text-align: center;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-user-select: none;
  cursor: default;
  z-index: 999999;
}

.button_base:hover {
  cursor: pointer;
}

.button_base2 {
  margin: 0;
  border: 0;
  font-size: 18px;
  position: relative;
  width: 140px;
  height: 30px;
  text-align: center;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-user-select: none;
  cursor: default;
}

.button_base:hover {
  cursor: pointer;
}


.b08_3d_pushback {
  perspective: 500px;
  -webkit-perspective: 500px;
  -moz-perspective: 500px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.b08_3d_pushback div {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  pointer-events: none;
  padding: 10px;
  border: #6a925d solid 2px;
  border-radius: 5px;
}

.b08_3d_pushback div:nth-child(1) {
  color: #001722;
  font-weight: 500;
  background-color: #001722;
  transform: rotateX(0deg) translateZ(0px);
  -webkit-transform: rotateX(0deg) translateZ(0px);
  -moz-transform: rotateX(0deg) translateZ(0px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  border: #6a925d solid 2px;
}

.b08_3d_pushback div:nth-child(2) {
  color: #6a925d;
  background-color: #001722;
  transform: rotateX(-179.5deg) translateZ(1px);
  -webkit-transform: rotateX(-179.5deg) translateZ(1px);
  -moz-transform: rotateX(-179.5deg) translateZ(1px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.b08_3d_pushback:hover div:nth-child(1) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(179.5deg);
  -webkit-transform: rotateX(179.5deg);
  -moz-transform: rotateX(179.5deg);
}

.b08_3d_pushback:hover div:nth-child(2) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -webkit-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -moz-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
}

 
.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 12px 48px;
  color: #6a925d;
  background: linear-gradient(to right, #6a925d 0, white 10%, #6a925d 20%);
  opacity: 1;
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

    
@keyframes shine {
  0%{
    background-position: 0;
  }
  60%{
    background-position: 180px;
  }
  100%{
    background-position: 180px;
  
  }
}

.welcome-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
}

.welcome-modal.click {
opacity: 0;
transition: 700ms;
}

.welcome-modal.click.hide {
  display: none;
}

.welcome-modal .info{
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px;
  width: 75vw;
  align-items: center;
  justify-content: center;
}

/** Button 2*/

.btn-shine2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 12px 48px;
  color: #6a925d;
  background: linear-gradient(to right, #005b85 0, white 10%, #005b85 20%);
  opacity: 1;
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

.b08_3d_pushback2 {
  perspective: 500px;
  -webkit-perspective: 500px;
  -moz-perspective: 500px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.b08_3d_pushback2 div {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  pointer-events: none;
  padding: 10px;
  border: #6a925d solid 2px;
  border-radius: 5px;
}

.b08_3d_pushback2 div:nth-child(1) {
  color: #001722;
  font-weight: 500;
  background-color: #eee;
  transform: rotateX(0deg) translateZ(0px);
  -webkit-transform: rotateX(0deg) translateZ(0px);
  -moz-transform: rotateX(0deg) translateZ(0px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  border: #005b85 solid 2px;
}

.b08_3d_pushback2 div:nth-child(2) {
  color: #005b85;
  background-color: #eee;
  transform: rotateX(-179.5deg) translateZ(1px);
  -webkit-transform: rotateX(-179.5deg) translateZ(1px);
  -moz-transform: rotateX(-179.5deg) translateZ(1px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.b08_3d_pushback2:hover div:nth-child(1) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(179.5deg);
  -webkit-transform: rotateX(179.5deg);
  -moz-transform: rotateX(179.5deg);
}

.b08_3d_pushback2:hover div:nth-child(2) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -webkit-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -moz-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
}


.b08_3d_pushback3 {
  perspective: 500px;
  -webkit-perspective: 500px;
  -moz-perspective: 500px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.b08_3d_pushback3 div {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  pointer-events: none;
  padding: 10px;
  border: #6a925d solid 2px;
  border-radius: 5px;
}

.b08_3d_pushback3 div:nth-child(1) {
  color: #001722;
  font-weight: 500;
  background-color: #fff;
  transform: rotateX(0deg) translateZ(0px);
  -webkit-transform: rotateX(0deg) translateZ(0px);
  -moz-transform: rotateX(0deg) translateZ(0px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  border: #005b85 solid 2px;
}

.b08_3d_pushback3 div:nth-child(2) {
  color: #005b85;
  background-color: #eee;
  transform: rotateX(-179.5deg) translateZ(1px);
  -webkit-transform: rotateX(-179.5deg) translateZ(1px);
  -moz-transform: rotateX(-179.5deg) translateZ(1px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.b08_3d_pushback3:hover div:nth-child(1) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(179.5deg);
  -webkit-transform: rotateX(179.5deg);
  -moz-transform: rotateX(179.5deg);
}

.b08_3d_pushback3:hover div:nth-child(2) {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -webkit-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
  -moz-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
}


#rehost,
#replatform,
#repurchase,
#refactor,
#encapsulate,
#retain,
#retire{
  transition: 700ms;
  animation: pulse 2s infinite;
  font-size: 10px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 63, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(44, 55, 204, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 95, 204, 0.3);
    box-shadow: 0 0 0 0 rgba(44, 63, 204, 0.3);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

#rehost:hover,
#replatform:hover,
#repurchase:hover,
#refactor:hover,
#encapsulate:hover,
#retain:hover,
#retire:hover{
  cursor: pointer;
  background-color: #005b8590;
  border-color: #005b8590;
  transition: 700ms;
  font-size: larger;
}

.welcome-icon {
  width: 5vw;
  height: 5vw;
  background-image: url("../static/icons/0-2.png"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* margin-right: 15px; */
  /* border: 2px solid white; */
  border-radius: 50%;
}

.contact-modal {
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  z-index: 999;
}
